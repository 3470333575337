import { combineReducers } from "redux";
import auth from "./store/reducers/auth";

import { LOGOUT_ACTION } from "./GlobalConstants";
const appReducer = combineReducers({
  auth: auth,
});

export const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === LOGOUT_ACTION) {
    state = undefined;
  }

  return appReducer(state, action);
};
