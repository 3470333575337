import React from "react";
import { makeStyles, Grid, Typography, Button } from "@material-ui/core";
import imageLG1 from "../assets/Group 7916.svg";
import imageLG2 from "../assets/Group 7965.svg";
import imageSM1 from "../assets/Group 8023.svg";
import imageSM2 from "../assets/Group 8025.svg";
import { FORMA_DJR_DECK, MR_EAVESXL_MODERN } from "../GlobalConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    padding: "5%",
    paddingTop: "10%",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "12%",
    },
  },
  text1Container: {
    justifyItems: "flex-start",
    [theme.breakpoints.up("md")]: {},
  },
  text1_1: {
    color: theme.palette.secondary.main,
    fontSize: 30,
    fontFamily: FORMA_DJR_DECK,
    fontWeight: "bold",
    [theme.breakpoints.up("md")]: {
      fontSize: 50,
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: 100,
      display: "none",
    },
  },
  text1_3: {
    color: theme.palette.secondary.main,
    fontSize: 30,
    fontFamily: FORMA_DJR_DECK,
    fontWeight: "bold",
    [theme.breakpoints.up("md")]: {
      fontSize: 50,
      display: "none",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "96%",
    },
  },
  text1_2: {
    color: theme.palette.primary.light,
    fontSize: 15,
    marginTop: 18,
    maxWidth: "100%",
    [theme.breakpoints.up("md")]: {
      fontSize: 25,
      maxWidth: "90%",
      marginTop: 18,
    },
    fontFamily: MR_EAVESXL_MODERN,
  },
  itemsContainer: {
    paddingTop: "10%",
    width: "100%",
    [theme.breakpoints.up("md")]: {
      //paddingLeft: "5%",
      paddingTop: 0,
    },
  },
  items: {
    borderStyle: "solid",
    borderWidth: 2,
    borderRadius: 26,
    borderColor: theme.palette.secondary.main,
    padding: "5%",
    paddingLeft: "5%",
    paddingRight: "2%",
    [theme.breakpoints.up("md")]: {
      //paddingLeft: "5%",
      maxWidth: "40%",
    },
  },
  itemText1: {
    color: theme.palette.secondary.main,
    fontFamily: FORMA_DJR_DECK,
    fontWeight: "bold",
    fontSize: 24,
    textAlign: "left",
    maxWidth: "55%",
    [theme.breakpoints.down("sm")]: {
      marginTop: "9%",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 30,
      maxWidth: "60%",
    },
  },
  itemText2: {
    color: theme.palette.primary.light,
    fontFamily: MR_EAVESXL_MODERN,
    fontSize: 16,
    textAlign: "left",
    maxWidth: "63%",
    [theme.breakpoints.up("md")]: {
      fontSize: 18,
      maxWidth: "80%",
      lineHeight: 1.5,
    },
    [theme.breakpoints.down("sm")]: {
      marginTop: "9%",
      lineHeight: 1.5,
    },
  },
  imageLG1: {
    display: "none",
    position: "relative",
    top: "4%",
    marginBottom: "12%",
    //left: "2%",
    [theme.breakpoints.down("lg")]: {
      left: "0%",
      //minHeight: 221.064,
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
      //minHeight: 221.064,
    },
  },
  imageLG2: {
    display: "none",
    position: "relative",
    top: "-6%",
    //left: "24%",
    [theme.breakpoints.down("lg")]: {
      left: "0%",
      //minHeight: 221.064,
    },
    [theme.breakpoints.up("md")]: {
      display: "block",
      //minHeight: 221.064,
    },
  },
  imageSM: {
    display: "block",
    position: "absolute",
    left: "50%",
    marginTop: "4%",
    [theme.breakpoints.down("xs")]: {},
    [theme.breakpoints.up("sm")]: {
      left: "65%",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
      //minHeight: 221.064,
    },
  },
  contactBtnContainer: {
    justifyContent: "flex-start",
    paddingLeft: "7%",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "7%",
    },
  },
  btnContact: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
    //padding: 10,
    paddingLeft: 30,
    paddingRight: 30,
    borderColor: theme.palette.secondary.main,
    borderStyle: "solid",
    minWidth: 150,
    //width: 175,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: FORMA_DJR_DECK,
    height: 40,
    [theme.breakpoints.down("sm")]: {
      minWidth: 150,
      fontSize: 20,
      paddingLeft: 20,
      paddingRight: 20,
    },
  },
  btnJoin: {
    marginTop: "10%",
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
    padding: 10,
    paddingLeft: 10,
    paddingRight: 10,
    borderColor: theme.palette.secondary.main,
    borderStyle: "solid",
    minWidth: 100,
    height: 40,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: FORMA_DJR_DECK,
    [theme.breakpoints.down("sm")]: {
      width: 100,
      fontSize: 17,
    },
  },
  text3: {
    color: theme.palette.secondary.main,
    fontSize: 20,
    fontFamily: FORMA_DJR_DECK,
    [theme.breakpoints.down("sm")]: {
      textAlign: "left",
    },
    [theme.breakpoints.up("md")]: {
      fontSize: 30,
    },
  },
}));
const Join = (props) => {
  const classes = useStyles();

  return (
    <Grid xs={12} item container className={classes.root}>
      <Grid xs={12} md={3} item container className={classes.text1Container}>
        <Typography className={classes.text1_1} align="left">
          Join Our Marketing Network!
          <br />
          <Typography className={classes.text1_2} align="left">
            To Mutually Grow as Advertisers and Publishers.
          </Typography>
        </Typography>
        <Typography className={classes.text1_3} align="left">
          Join Our
          <br />
          Marketing Network!
          <Typography className={classes.text1_2} align="left">
            To Mutually Grow as Advertisers and Publishers.
          </Typography>
        </Typography>
      </Grid>
      <Grid xs={12} md={9} item container className={classes.itemsContainer}>
        <Grid xs={12} md={5} item container className={classes.items}>
          <Typography className={classes.itemText1}>
            Join as an Advertiser.
          </Typography>
          <div className={classes.imageLG1}>
            <img src={imageLG1} alt="image1" />
          </div>
          <div className={classes.imageSM}>
            <img src={imageSM1} alt="image2" />
          </div>
          <Typography className={classes.itemText2}>
            Our publishers have the reach to engage with your ideal target
            customer and drive quality acquisitions with all possible optimum
            resources.
          </Typography>
          <Grid item container xs={12} justify="flex-start">
            <Button
              className={classes.btnJoin}
              target="_blank"
              href="https://clickoz.offer18.com/m/signup_self_adv"
            >
              Join
            </Button>
          </Grid>
        </Grid>
        <Grid xs={12} md={1} item style={{ minHeight: 30 }} />
        <Grid xs={12} md={5} item container className={classes.items}>
          <Typography className={classes.itemText1}>
            Join as a Publisher.
          </Typography>
          <div className={classes.imageSM}>
            <img
              src={imageSM2}
              alt="image3"
              style={{ maxWidth: "80%", maxHeight: "80%" }}
            />
          </div>

          <div className={classes.imageLG2}>
            <img src={imageLG2} alt="image4" />
          </div>
          <Typography className={classes.itemText2}>
            With campaigns from all sectors we make sure that our publishers go
            nowhere else. You just need to choose the ones that work for you.
          </Typography>
          <Grid
            item
            container
            xs={12}
            justify="flex-start"
            style={{ paddingTop: "0%", paddingBottom: "1%" }}
          >
            <Button
              className={classes.btnJoin}
              target="_blank"
              href="https://clickoz.offer18.com/m/signup_self_aff"
            >
              Join
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Grid
        xs={12}
        item
        container
        justify="center"
        style={{ paddingTop: "7%" }}
      >
        <Grid
          xs={5}
          md={7}
          item
          container
          justify="flex-end"
          alignContent="center"
        >
          <Typography
            className={classes.text3}
          >{`Got a query?  Connect with us.`}</Typography>
        </Grid>
        <Grid xs={7} md={5} item container>
          <Grid item container xs={12} className={classes.contactBtnContainer}>
            <Button
              className={classes.btnContact}
              onClick={props.focusOnContact}
            >
              Connect Now
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Join;
