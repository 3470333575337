import React from "react";
import { makeStyles, Grid, Button } from "@material-ui/core";
import { FORMA_DJR_DECK } from "../../GlobalConstants";

const useStyles = makeStyles((theme) => ({
  btn1Wrapper: {
    paddingTop: 20,
    paddingBottom: 40,
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      //spacing: 0,
    },
  },
  contactBtnContainer: {
    padding: 12,
    // maxWidth: "26%",

    justifyContent: "flex-end",
    [theme.breakpoints.down("sm")]: {
      justifyContent: "flex-start",
      paddingRight: 0,
      maxWidth: "42%",
    },
  },
  btnContact: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.secondary,
    padding: 10,
    paddingLeft: 30,
    paddingRight: 30,
    borderColor: theme.palette.secondary.main,
    borderStyle: "solid",
    width: 175,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: FORMA_DJR_DECK,
    [theme.breakpoints.down("sm")]: {
      width: 140,
      fontSize: 14,
    },
  },
  signUpBtnContainer: {
    padding: 12,
    [theme.breakpoints.down("sm")]: {
      paddingTop: 12,
      paddingBottom: 12,
      maxWidth: "42%",
    },
  },
  btnSignUp: {
    padding: 10,
    paddingLeft: 20,
    paddingRight: 20,
    borderWidth: 2,
    borderColor: theme.palette.secondary.main,
    borderStyle: "solid",
    width: 175,
    fontSize: 20,
    fontWeight: "bold",
    fontFamily: FORMA_DJR_DECK,
    [theme.breakpoints.down("sm")]: {
      width: 100,
      fontSize: 14,
    },
  },
}));
const CustomButton2 = (props) => {
  const classes = useStyles();

  return (
    <Grid
      xs={12}
      item
      container
      justify="center"
      className={classes.btn1Wrapper}
      //spacing={3}
    >
      <Grid
        item
        container
        xs={6}
        className={classes.contactBtnContainer}
        style={{ marginLeft: props.leftMargin }}
      >
        <Button className={classes.btnContact} onClick={props.focusOnContact}>
          Contact Us
        </Button>
      </Grid>
      <Grid item container xs={6} className={classes.signUpBtnContainer}>
        <Button
          className={classes.btnSignUp}
          target="_blank"
          href="https://clickoz.offer18.com/m/login"
        >
          Signup
        </Button>
      </Grid>
    </Grid>
  );
};

export default CustomButton2;
