import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import { FORMA_DJR_DECK } from "../GlobalConstants";
import { withRouter } from "react-router";

const useStyles = makeStyles((theme) => ({
  LinkWrapper: {
    paddingBottom: "3%",
    [theme.breakpoints.down("sm")]: {
      paddingBottom: "8%",
    },
  },
  linkTxt: {
    fontFamily: FORMA_DJR_DECK,
    color: theme.palette.secondary.main,
    fontSize: 18,
  },
  divLink: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));
const ServicesLink = (props) => {
  const classes = useStyles();

  const navigate = () => {
    if (props.link !== "/") {
      props.link && props.history.push(props.link);
      window.scrollTo(0, 0);
    } else {
      props.history.push({ pathname: props.link, state: props.scroll });
    }
  };

  return (
    <Grid
      xs={12}
      item
      container
      justify="flex-start"
      className={classes.LinkWrapper}
    >
      <div className={classes.divLink} onClick={navigate}>
        <Typography className={classes.linkTxt}>{props.label}</Typography>
      </div>
    </Grid>
  );
};

export default withRouter(ServicesLink);
