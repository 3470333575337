import React from "react";

const PrivacyPolicy = (props) => {
  return (
    <div style={{ textAlign: "left", padding: "10%" }}>
      <h1>PRIVACY POLICY</h1>
      <p>
        Your privacy is important to us. This Privacy Statement describes the
        types of information that Adyll Media Pvt. Ltd, Its Subsidiaries or
        Affiliates ( collectively termed as “Adyll Media Pvt. Ltd” or “We” or
        “us” or variations of the same) collects from and about you when you
        visit our websites, https://adyll.com/ (collectively called the “Site”
        or “Sites”). This Privacy Statement also explains as to how Adyll Media
        Pvt. Ltd may use and disclose such information, as well as your ability
        to control certain uses of it.
      </p>
      <p>
        By using this site, you represent that you have attained the age of
        majority in your state (as per the law applicable to you) and that you
        have consented to allow your minor dependants to use this site.
      </p>
      <p>
        We strongly advise you to read this Policy carefully. Any usage of our
        services or site will signify that you have read this policy and have
        accepted the same after application of mind and due deliberation.
      </p>
      <p>
        Our Contact Details are stated at the end of this page under the head
        “Contact Details”. Whenever any reference is made regarding your right
        to contact us – it will mean and imply contacting us at the details
        stated under the head “Contact Details”.
      </p>
      <h2>DATA CONTROLLER</h2>
      <p>
        Adyll Media Pvt. Ltd will be the controller of Personal Information that
        you chose to provide to us.
      </p>
      <h2>INFORMATION COLLECTION</h2>
      <p>
        Adyll Media Pvt. Ltd collects information that you choose to provide to
        us, such as when you request information or a demonstration from us,
        create an account with us, download gated content from us, sign up for
        our email lists, use our Site, provide us with personal information to
        complete a transaction, verify your credit card, place an order, arrange
        for a delivery or return a purchased item or otherwise contact us – in
        any manner.
      </p>
      <p>
        The information we collect may include your name, email address,
        physical address, telephone number, and screen / user name. Adyll Media
        Pvt. Ltd does not collect, store or transmit payment information of any
        sort, and any information you give of this nature will be directly
        entered by you on a well known payment processor or payment gateway or
        acquiring bank website, which is PCI DSS standard secured and which has
        taken all possible cyber security measures to store and transmit such
        payment information securely to relevant banks and or credit card
        processors, for completion of your transaction with Adyll Media Pvt.
        Ltd. If you are not in agreement with Adyll Media Pvt. Ltd’s choice of
        payment gateway or payment processor or acquiring bank, please do not
        proceed with the transaction. Adyll Media Pvt. Ltd takes no
        responsibility for such information compromise if you have willingly
        agreed to proceed with the transaction.
      </p>
      <p>
        You may be required to provide certain information to create your Adyll
        Media Pvt. Ltd account or to utilize our services.
      </p>
      <p>
        Adyll Media Pvt. Ltd does not collect Sensitive Personal Information
        such as information relating to health, race, ethnicity, sexual
        orientation and political opinions. Any updates to the types of
        information we collect will be provided on this Site and you will have
        opt-in rights as set forth herein. In future, should Adyll Media Pvt.
        Ltd decide to collect Sensitive Personal Information, we will only do so
        if you provide your consent. If you wish to opt-in to the collection and
        use of Sensitive Personal Information by us to provide services to you,
        which may include the transfer of such Sensitive Personal Information to
        third parties, then you may opt-in to such uses by checking the ‘opt-in’
        box in your user preferences. If none of the available options meets
        your requirements, or you have other questions, please contact us.
      </p>
      <p>
        We may use cookies or other technologies to automatically collect
        certain information when you use our Site or interact with our emails
        and online or mobile advertisements. For example, we may automatically
        collect certain information from you, such as your browser type,
        operating system, software version, Internet Protocol (“IP”) address,
        mobile or ISP carrier information, and the domain name from which you
        accessed the Site. We may collect information about your use of the Site
        including the date and time at which you access the Site, the areas or
        pages of the Site that you visit, the amount of time you spend using the
        Site, the number of times you return, whether you open forward or
        click-through emails and ads, and other such usage data.
      </p>
      <p>
        You may adjust your browser or operating system settings to limit the
        above-stated tracking or to decline cookies, but by doing so, you may
        not be able to use certain features on the Site or take full advantage
        of all of our offerings. Check the “Help” menu of your browser or
        operating system to learn how to adjust your tracking settings or cookie
        preferences.
      </p>
      <h2>INFORMATION USE</h2>
      <p>
        Adyll Media Pvt. Ltd may use the information that we collect from and
        about you to:
      </p>
      <p>
        Respond to your inquiries, provide services, fulfill orders, process
        payments and help prevent transactional fraud, and contact and
        communicate with you;
      </p>
      <p>Develop new products or services;</p>
      <p>
        Provide you with customized content, targeted offers, and advertising on
        the Site, via telephone or via email;
      </p>
      <p>
        Contact you with information, newsletters and promotional materials from
        Adyll Media Pvt. Ltd or on behalf of our partners and affiliates;
      </p>
      <p>
        Research, address problems, and improve the usage and operations of the
        Site;
      </p>
      <p>Protect the security or integrity of the Site and our business; and</p>
      <p>
        For such other purposes as are described to you at the point of data
        collection.
      </p>
      <h3>
        Adyll Media Pvt. Ltd may share the information that we collect from and
        about you:
      </h3>
      <p>
        with our service providers / vendors , if in our opinion, the disclosure
        will enable them to perform a business, professional or technical
        support function for us; or
      </p>
      <p>
        with our own affiliates, in order to improve the delivery of our
        services and for marketing purposes; or
      </p>
      <p>
        to respond to judicial processes or provide information to law
        enforcement or regulatory agencies or in connection with an
        investigation as permitted by law, or otherwise - as required by law; or
      </p>
      <p>
        for such other activities as are described to you at the point of
        collection.
      </p>
      <p>
        If you wish to limit or restrict the disclosure of your Personal
        Information to third parties, you may opt-out of such third-party
        disclosures by sending us a notice via email at the following email id:
        support@adyll.com
      </p>
      <p>
        Adyll Media Pvt. Ltd may sell or purchase assets during the normal
        course of our business. If another entity acquires us or any of our
        assets, information we have collected about you may be transferred to
        such entity. In addition, if any Insolvency, bankruptcy or
        reorganization proceeding is brought by or against us, such information
        may be considered our asset and may be sold or transferred to third
        parties. Should such a sale or transfer occur, we will use reasonable
        efforts to require that the transferee uses personal information
        provided through the Site - in a manner that is consistent with this
        Privacy Statement.
      </p>
      <h2>SERVICE PROVIDERS</h2>
      <p>
        Adyll Media Pvt. Ltd may contract with third parties to perform
        functions relating to the Services (“Service Providers”) including,
        without limitation, payment processors and cloud service hosting
        providers. Service Providers may have access to your personal
        information needed to perform their business functions, but may not use
        or share that personal information for other purposes.
      </p>
      <h2>EMAIL MARKETING</h2>
      <p>
        With your permission, we may, send you emails about our Site, new
        products and services, and other updates. By “permission” , we mean
        express permission granted by you. A permission to send commercial or
        marketing emails may be through any of the following instances (the list
        is inclusive and not exhaustive) which we deem as existing business
        relationship with or inquiry from you:
      </p>
      <p>
        Entering your email address on our Site - with no indication that you
        would not like to be emailed.
      </p>
      <p>
        Entering your email address as part of either attempting to order or
        ordering a product or service from us - with no indication that you
        would not like to be emailed.
      </p>
      <p>
        Entering your email address as part of an abandoned cart - with no
        indication that you would not like to be emailed.
      </p>
      <p>
        Entering your email address as part of participation in any contest,
        event or survey conducted by us - wherein we have informed you that we
        will be sending you marketing emails.
      </p>
      <p>
        Subscribing to an email newsletter - by filling-out a form on our Site.
      </p>
      <p>
        Any instance - where in completing a form, you have checked an opt-in
        checkbox indicating your willingness to be contacted through email
        provided the checkbox is unchecked by default and we have informed you
        that the nature of the emails will be commercial.
      </p>
      <p>
        Our marketing emails will conspicuously contain a notice that you can
        opt out of receiving more marketing emails from us. Our marketing emails
        will prominently display a one-click unsubscribe or opt-out option. No
        charges of any nature whatsoever would be imposed on you for
        entertaining your request of opting out. Once you have opted out or
        unsubscribed, we will refrain from sending marketing emails to you.
      </p>
      <p>
        We may use your personal information, including but not limited to your
        name, address, telephone, email address and other relevant data, to
        conduct our business, improve our Sites, develop new products and
        services, provide information and support, to better understand your
        needs and interests, personalize communications and advertising, meet
        contractual obligations, and provide a quality experience for you. We
        may use your personal information, including your email address, to:
      </p>
      <p>Communicate, interact and build our relationship with you;</p>
      <p>
        Customize the content, products and services that are offered to you;
      </p>
      <p>Contact you with information about us and affiliated third-parties;</p>
      <p>
        Process, fulfill and follow up on transactions and requests for
        products, services, support, and information;
      </p>
      <p>Verify your authority to enter and use our services;</p>
      <p>Engage in market research and analysis;</p>
      <p>
        Measure, analyze and improve our products and services, the
        effectiveness of our websites, and our advertising and marketing;
      </p>
      <p>Comply with legal requirements;</p>
      <p>Provide targeting advertising;</p>
      <p>
        Send you marketing materials, via email, including our newsletter, and
        to notify you about products and services that we believe would be of
        interest to you;
      </p>
      <p>
        Deter, detect, and prevent fraud and other prohibited or illegal
        activities; or
      </p>
      <p>
        Inform you regarding new services, releases, upcoming events and changes
        in our terms and conditions or privacy policy.
      </p>
      <p>
        You may manage your receipt of marketing and non-transactional emails by
        clicking on the “unsubscribe” link located on the bottom of our
        marketing and non-transactional emails. You can also opt-out or
        unsubscribe by sending us an email or by following the opt-out
        instructions in the email or newsletter, or by sending an e-mail to us.
      </p>
      <h2>TELEMARKETING</h2>
      <p>
        Regardless of the fact that your telephone and/or cell number may be
        listed with the Federal Do-Not-Call Registry or your local State
        Do-Not-Call list, by providing us your telephone and/or cell number -
        you are providing express written consent to receive future information
        (including telemarketing) about products and services from us and/or our
        affiliates. You hereby agree and consent to our contacting you by using
        the information you have provided and will provide to us. This means
        that we may contact you by e-mail, phone and/or cell number (including
        use of automated dialing equipment and/or pre-recorded calls), text
        (SMS) message, social networks or any other means of communication that
        your wireless or other telecommunications device may be capable of
        receiving. You are not required to agree directly or indirectly or enter
        into an agreement regarding our telemarketing efforts as a condition of
        purchasing any goods or services from us or our affiliates. If you do
        not want to receive marketing calls, please contact us at the details
        stated at the end of this document.
      </p>
      <p>
        We shall attempt to honour your request to opt-out from the mailing list
        immediately and do the needful at the earliest. You may not be able to
        opt out of all information sharing, however, such as information sharing
        with credit card processors in connection with products or services that
        you order from us. While we offer you some control over marketing
        communication, certain transactional, relationship, and legally required
        communications will not be affected by the choices you have made about
        marketing communications.
      </p>
      <h2>YOUR ACCESS TO YOUR PERSONAL INFORMATION</h2>
      <p>
        If you have an account on our Site, you may sign in to your account and
        update your personal information contained therein anytime. We will not
        be responsible for reminding you regarding the updation of your personal
        information.
      </p>
      <h2>USER CHOICES</h2>
      <p>
        If you would like to update, modify, or remove your personal information
        or cancel subscription preferences, you may contact us. On certain
        Site(s), you may be able to make these choices directly by logging into
        your account. You may unsubscribe from any of our promotional emails by
        clicking on the “unsubscribe” link in any promotional email that you
        receive from us. Changes made to your profile will be effective once
        saved into our systems in accordance with our standard procedures for
        implementing user questions regarding your Personal Information.
      </p>
      <p>
        You have a legal right to obtain access to your Personal Information
        that we maintain about you. Additionally, if you believe that the
        Personal Information (in our records) is inaccurate, you may have the
        legal right to request that we correct or amend the information. To make
        these requests, or to object to our processing of your Personal
        Information or withdraw consent to such processing (rights provided by
        the laws of certain countries), you may contact us.
      </p>
      <p>
        You may follow the directions in a marketing e-mail or direct mail or
        mobile communication that you receive from us to effect changes to your
        use of the Web Site or you may contact us.
      </p>
      {/* <h2>DATA PROCESSING WITHIN EUROPEAN UNION</h2>
In case your Data is processed with the European Union, it will be managed in accordance with this Privacy Policy, the applicable Terms and in accordance with the General Data Protection Regulation (GDPR).

<h2>DATA PROCESSING OUTSIDE EUROPEAN UNION</h2>
In case your Data is processed outside the European Union, we will ensure that the same is done in accordance with good security regulations and in compliance with applicable laws and regulations pertaining to Data Privacy. */}
      {/* <h3>OPT-IN</h3>

You have the right to opt-in to the collection, tracking and use of your personal data. We will not collect your personal data unless you affirmatively give your consent to the same. Separate consent is not required for the performace of our Contractual obligations, to meet legal obligations or to ensure compliance with Laws, for legitimate interests / requirements, better performance of our obligations or in order to provide you services in a better manner.

<h3>OPT-OUT/CORRECTION</h3>
You will, at any time, have the right to opt-out or withdraw your consent to the collection or use of your data. If you withdraw your consent, please be advised that it may inhibit or limit our ability to provide you with certain services and to perform certain functions. However, you may continue to use the remaining / unaffected services of our Site.

Further, you may request to view and change your data preferences, security settings, and consent preferences in your account dashboard - provided that you set up a user profile on our site, or by sending us an email to that effect. Additionally, you may opt out of or withdraw consents, ask us to correct errors in the collected or submitted data or request that your personal data be erased or removed. If you withdraw your consent, Company will delete or remove the relevant personal data collected from its records.

Any request for opting out may be mailed at support@Adyll.com */}
      <h2>PUBLIC FORUMS</h2>
      Any information that you may disclose in comments or on other public areas
      of the Site becomes public information. We advise you to exercise due care
      and caution when disclosing personal information on these public areas.
      <h2>LINKS TO OTHER WEBSITES</h2>
      Our site may have links to third-party websites, which may have privacy
      policies that differ from our own. We are not responsible or liable in any
      manner whatsoever for the practices adopted by such sites. You are advised
      to peruse the Privacy Policies of any such Third Parties before providing
      any information to them.
      <h2>CHILDREN’S PRIVACY</h2>
      Protecting children’s privacy is very important to us. We do not knowingly
      collect any personal information from, children under the age of thirteen.
      <h2>CONFIDENTIALITY & DATA SECURITY</h2>
      The information stored on the sites is treated confidential. We have taken
      physical, administrative, and technical steps to safeguard the information
      that we collect from and about our customers and Site visitors. We make
      every effort to ensure the integrity and security of our network and
      systems. Still, if we become aware about any unauthorised or unlawful
      access or alteration in relation to your information stored on our site,
      we will promptly notify you.
      <h2>PRIVACY STATEMENT CHANGES / AMENDMENTS</h2>
      Adyll Media Pvt. Ltd reserves the right to revise this Privacy Statement
      at any time without any notice. Whenever the Privacy Statement is revised,
      we will post the changes / amendments on the Site. If you use the site
      after a change to the Privacy Statement has been made effective, it will
      signify that you have agreed to all such changes. If we change the Privacy
      Statement in a material way, we will provide appropriate notice to you.
      The decision on the point as to whether a change is material or not is
      completely our prerogative and will not be subject to challenge in any
      Court of Law.
      <h2>APPLICABLE LAW & JURISDICTION</h2>
      Any dispute arising out of the usage or related to this Policy shall be
      subject to Indian Laws and the Courts at Delhi, India shall have exclusive
      jurisdiction to adjudicate over the same.
      <h2>QUESTIONS</h2>
      If you have questions about this Privacy Statement, please feel free to
      email us at support@adyll.com.
      <h2>CONTACT US</h2>
      Wherever any reference has been made in this document in relation to
      contacting us, please contact us in the following manner: Through email:
      support@adyll.com
    </div>
  );
};

export default PrivacyPolicy;
