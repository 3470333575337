import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";

import dispAds from "../assets/dispAdds.svg";
import paidSocial from "../assets/paid.svg";
import refresh from "../assets/refresh.svg";
import blog from "../assets/blog.svg";
import Outline from "../assets/Outline.svg";
import surface1 from "../assets/surface1.svg";
import phone from "../assets/phone.svg";
import megaphone from "../assets/megaphone.svg";
import mobileHandOnlineMegaphone from "../assets/mobile-hand-online-megaphone.svg";
import automation from "../assets/automation.svg";
import influencer from "../assets/Influencer Marketing_2451061.svg";
import CustomButton1 from "../assets/CustomComponents/CustomButton1";
import picture from "../assets/picture.svg";
import pen from "../assets/pen.svg";
import Out_line from "../assets/Out line.svg";
import socialmediamarketing from "../assets/social-media-marketing (1).svg";
import emailmarketing from "../assets/email-marketing.svg";
import Analytics from "../assets/Analytics.svg";
import { MR_EAVESXL_MODERN, FORMA_DJR_DECK } from "../GlobalConstants";

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 20,
    flexGrow: 1,
    paddingTop: "7%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "10%",
      //justifyItems: "center",
    },
    [theme.breakpoints.up("md")]: {
      paddingLeft: "12%",
    },
  },
  text1Container: {
    justifyItems: "flex-start",
    [theme.breakpoints.up("md")]: {
      //: "10%",
      //justifyItems: "center",
    },
  },
  text1_1: {
    color: theme.palette.primary.main,
    fontSize: 30,
    fontFamily: FORMA_DJR_DECK,
    fontWeight: "bold",
    [theme.breakpoints.up("md")]: {
      maxWidth: "70%",
      fontSize: 50,
    },
  },
  text1_2: {
    color: theme.palette.primary.light,
    fontSize: 16,
    fontFamily: MR_EAVESXL_MODERN,
    marginTop: 10,
    [theme.breakpoints.up("md")]: {
      maxWidth: "80%",
      fontSize: 25,
      marginTop: 20,
    },
  },
  lgImg: {
    maxHeight: 70,
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  smImg: {
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  itemsContainer: {
    paddingTop: "10%",

    [theme.breakpoints.up("md")]: {
      //paddingLeft: "6%",
      paddingTop: 0,
    },
  },
  items1: {
    marginBottom: "10%",
  },
  imgWrapper: {
    minHeight: 40,
    [theme.breakpoints.up("md")]: {
      minHeight: 50,
    },
  },
  text2: {
    color: theme.palette.primary.main,
    fontSize: 15,
    textAlign: "left",
    fontFamily: FORMA_DJR_DECK,
    maxWidth: "80%",
    marginTop: "2%",
    [theme.breakpoints.up("md")]: {
      fontSize: 30,
      maxWidth: "65%",
    },
  },
}));
const Purpose = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <div ref={ref}>
      <Grid xs={12} item container className={classes.root}>
        <Grid xs={12} md={3} item container className={classes.text1Container}>
          <Typography className={classes.text1_1} align="left">
            What do we do?
            <br />
            <Typography className={classes.text1_2} align="left">
              We believe in providing quality services to our partners
            </Typography>
          </Typography>
        </Grid>
        <Grid
          xs={12}
          md={8}
          item
          //spacing={2}
          container
          className={classes.itemsContainer}
        >
          <Grid xs={4} item container className={classes.items1}>
            <Grid
              xs={12}
              container
              item
              justify="flex-start"
              className={classes.imgWrapper}
            >
              <img
                src={dispAds}
                className={classes.lgImg}
                style={{ width: 61, height: 49 }}
                alt="add"
              />
              <img
                src={dispAds}
                className={classes.smImg}
                style={{ width: 34.4, height: 33.84 }}
                alt="add"
              />
            </Grid>

            <Typography className={classes.text2}>Display Ads</Typography>
          </Grid>

          <Grid xs={4} item container className={classes.items1}>
            <Grid
              xs={12}
              container
              item
              justify="flex-start"
              className={classes.imgWrapper}
            >
              <img
                src={paidSocial}
                className={classes.lgImg}
                style={{ width: 72.52, height: 61.3 }}
                alt="add"
              />
              <img
                src={paidSocial}
                className={classes.smImg}
                style={{ width: 40.9, height: 34.57 }}
                alt="add"
              />
            </Grid>

            <Typography className={classes.text2}>Paid Social</Typography>
          </Grid>
          <Grid xs={4} item container className={classes.items1}>
            <Grid
              xs={12}
              container
              item
              justify="flex-start"
              className={classes.imgWrapper}
            >
              <img
                src={blog}
                className={classes.lgImg}
                style={{ width: 71.48, height: 66.72 }}
                alt="add"
              />
              <img
                src={refresh}
                className={classes.lgImg}
                style={{ width: 36.67, height: 40.04, marginTop: 27 }}
                alt="add"
              />
              <img
                src={blog}
                className={classes.smImg}
                style={{ width: 40.31, height: 37.63 }}
                alt="add"
              />
              <img
                src={refresh}
                className={classes.smImg}
                style={{ width: 20.65, height: 22.58, marginTop: 15 }}
                alt="add"
              />
            </Grid>

            <Typography className={classes.text2}>Remarketing</Typography>
          </Grid>
          <Grid xs={4} item container className={classes.items1}>
            <Grid
              xs={12}
              container
              item
              justify="flex-start"
              className={classes.imgWrapper}
            >
              <img
                src={Outline}
                className={classes.lgImg}
                style={{ width: 57.09, height: 68.5 }}
                alt="add"
              />
              <img
                src={surface1}
                className={classes.lgImg}
                style={{ width: 17.35, height: 17.7, marginTop: 40 }}
                alt="add"
              />
              <img
                src={Outline}
                className={classes.smImg}
                style={{ width: 32.19, height: 38.63 }}
                alt="add"
              />
              <img
                src={surface1}
                className={classes.smImg}
                style={{ width: 9.79, height: 9.98, marginTop: 22 }}
                alt="add"
              />
            </Grid>

            <Typography className={classes.text2}>Paid Search</Typography>
          </Grid>

          <Grid xs={4} item container className={classes.items1}>
            <Grid
              xs={12}
              container
              item
              justify="flex-start"
              className={classes.imgWrapper}
            >
              <img
                src={phone}
                className={classes.lgImg}
                style={{ width: 67.5, height: 67.5 }}
                alt="add"
              />
              <img
                alt="add"
                src={megaphone}
                className={classes.lgImg}
                style={{ width: 34.13, height: 31.24, marginTop: 20 }}
              />
              <img
                src={phone}
                className={classes.smImg}
                style={{ width: 38.07, height: 38.07 }}
                alt="add"
              />
              <img
                src={megaphone}
                className={classes.smImg}
                style={{ width: 19.25, height: 17.62, marginTop: 10 }}
                alt="add"
              />
            </Grid>

            <Typography className={classes.text2}>
              Affiliate Marketing
            </Typography>
          </Grid>
          <Grid xs={4} item container className={classes.items1}>
            <Grid
              xs={12}
              container
              item
              justify="center"
              className={classes.imgWrapper}
              style={{ flexDirection: "column" }}
            >
              <img
                src={mobileHandOnlineMegaphone}
                className={classes.lgImg}
                style={{ width: 38.51, height: 24.21, marginLeft: 15 }}
                alt="add"
              />
              <img
                src={automation}
                className={classes.lgImg}
                style={{ width: 71.06, height: 71.06 }}
                alt="add"
              />
              <img
                src={mobileHandOnlineMegaphone}
                className={classes.smImg}
                style={{ width: 21.72, height: 13.65, marginLeft: 9 }}
                alt="add"
              />

              <img
                src={automation}
                className={classes.smImg}
                style={{ width: 40.08, height: 40.08 }}
                alt="add"
              />
            </Grid>

            <Typography className={classes.text2}>
              Programatic Advertising
            </Typography>
          </Grid>

          <Grid xs={4} item container className={classes.items1}>
            <Grid
              xs={12}
              container
              item
              justify="flex-start"
              className={classes.imgWrapper}
            >
              <img
                src={influencer}
                className={classes.lgImg}
                style={{ width: 60, height: 60 }}
                alt="add"
              />
              <img src={influencer} className={classes.smImg} alt="add" />
            </Grid>
            <Typography className={classes.text2}>
              {`Influencer Marketing`}
            </Typography>
          </Grid>
          <Grid xs={4} item container className={classes.items1}>
            <Grid
              xs={12}
              container
              item
              justify="flex-start"
              className={classes.imgWrapper}
            >
              <img
                src={picture}
                className={classes.lgImg}
                style={{ width: 60, height: 60 }}
                alt="add"
              />
              <img
                src={picture}
                className={classes.smImg}
                style={{ width: 33.84, height: 33.84 }}
                alt="add"
              />
            </Grid>

            <Typography className={classes.text2}>
              Creactives to scale
            </Typography>
          </Grid>
          <Grid xs={4} item container className={classes.items1}>
            <Grid
              xs={12}
              container
              item
              justify="flex-start"
              className={classes.imgWrapper}
            >
              <img
                src={Out_line}
                className={classes.lgImg}
                style={{ width: 56, height: 56 }}
                alt="add"
              />
              <img
                alt="add"
                src={pen}
                className={classes.lgImg}
                style={{
                  width: 30.57,
                  height: 30.57,
                  marginTop: 20,
                  marginLeft: 4,
                }}
              />
              <img
                alt="add"
                src={Out_line}
                className={classes.smImg}
                style={{ width: 31.58, height: 31.58 }}
              />
              <img
                alt="add"
                src={pen}
                className={classes.smImg}
                style={{
                  width: 17.24,
                  height: 17.24,
                  marginTop: 10,
                  marginLeft: 4,
                }}
              />
            </Grid>

            <Typography
              className={classes.text2}
            >{`Branding & Design`}</Typography>
          </Grid>
          <Grid xs={4} item container className={classes.items1}>
            <Grid
              xs={12}
              container
              item
              justify="flex-start"
              className={classes.imgWrapper}
            >
              <img
                alt="add"
                src={socialmediamarketing}
                className={classes.lgImg}
                style={{ width: 64.79, height: 67.32 }}
              />
              <img
                alt="add"
                src={socialmediamarketing}
                className={classes.smImg}
                style={{ width: 36.54, height: 37.97 }}
              />
            </Grid>

            <Typography className={classes.text2}>Mobile Marketing</Typography>
          </Grid>
          <Grid xs={4} item container className={classes.items1}>
            <Grid
              xs={12}
              container
              item
              justify="flex-start"
              className={classes.imgWrapper}
            >
              <img
                alt="add"
                src={emailmarketing}
                className={classes.lgImg}
                style={{ width: 61, height: 72.94 }}
              />
              <img
                alt="add"
                src={emailmarketing}
                className={classes.smImg}
                style={{ width: 34.4, height: 41.4 }}
              />
            </Grid>

            <Typography className={classes.text2}>Email Marketing</Typography>
          </Grid>
          <Grid xs={4} item container className={classes.items1}>
            <Grid
              xs={12}
              container
              item
              justify="flex-start"
              className={classes.imgWrapper}
            >
              <img
                alt="add"
                src={Analytics}
                className={classes.lgImg}
                style={{ width: 60, height: 56 }}
              />
              <img
                alt="add"
                src={Analytics}
                className={classes.smImg}
                style={{ width: 33.84, height: 31.58 }}
              />
            </Grid>

            <Typography
              className={classes.text2}
            >{`Analytics & Insight`}</Typography>
          </Grid>
          <CustomButton1 focusOnContact={props.focusOnContact} />
        </Grid>
      </Grid>
    </div>
  );
});

export default Purpose;
