import React from "react";
import { makeStyles, Grid, Typography } from "@material-ui/core";
import iconLG from "../assets/Path 10529.svg";
import { FORMA_DJR_DECK, MR_EAVESXL_MODERN } from "../GlobalConstants";
import CustomButton2 from "../assets/CustomComponents/CustomButton2";
import CustomButton3 from "../assets/CustomComponents/CustomButton3";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.primary.main,
    width: "100%",
    padding: "5%",
    paddingTop: "8%",
    paddingLeft: "8%",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "12%",
    },
  },
  text1Container: {
    justifyItems: "flex-start",
    [theme.breakpoints.up("md")]: {
      marginTop: -18,
    },
  },
  text1_1: {
    color: theme.palette.secondary.main,
    fontSize: 30,

    [theme.breakpoints.up("md")]: {
      fontSize: 50,
      lineHeight: 1,
      maxWidth: "90%",
    },
    fontFamily: FORMA_DJR_DECK,
    fontWeight: "bold",
  },
  text1_2: {
    color: theme.palette.primary.light,
    fontSize: 16,
    [theme.breakpoints.up("md")]: {
      fontSize: 25,
      maxWidth: "66%",
      marginTop: 15,
    },
    [theme.breakpoints.down("sm")]: { marginTop: 10, maxWidth: "70%" },
    fontFamily: MR_EAVESXL_MODERN,
  },
  itemsContainer: {
    paddingTop: "10%",
    [theme.breakpoints.up("md")]: {
      paddingLeft: "5%",
      paddingTop: 0,
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  itemsContainer1: {
    padding: 8,
    paddingTop: "15%",
    [theme.breakpoints.down("sm")]: {
      paddingLeft: "2%",
      paddingTop: "7%",
    },
    [theme.breakpoints.up("md")]: {
      display: "none",
    },
  },
  items1: {
    marginBottom: "7%",
    maxHeight: 15,
    [theme.breakpoints.down("sm")]: {
      marginLeft: "12%",
      justifyContent: "center",
      paddingLeft: "2%",
      maxWidth: "70%",
      marginBottom: "4%",
      maxHeight: 30,
    },
  },
  text2: {
    fontFamily: MR_EAVESXL_MODERN,
    color: theme.palette.primary.light,
    fontSize: 16,
    textAlign: "left",
    marginLeft: "8%",
    [theme.breakpoints.up("md")]: { fontSize: 30, maxWidth: "100%" },
  },
  imgWrapper: {
    [theme.breakpoints.up("md")]: {
      //paddingLeft: "1%",
      paddingTop: "1%",
    },
  },
}));
const Expect = React.forwardRef((props, ref) => {
  const classes = useStyles();

  return (
    <Grid xs={12} item container className={classes.root} ref={ref}>
      <Grid xs={12} md={3} item container className={classes.text1Container}>
        <Typography className={classes.text1_1} align="left">
          What can you expect?
          <br />
          <Typography className={classes.text1_2} align="left">
            We ensure all the desired results out of this you wish for.
          </Typography>
        </Typography>
      </Grid>
      <Grid
        xs={12}
        md={8}
        item
        spacing={2}
        container
        className={classes.itemsContainer}
      >
        <Grid xs={12} md={6} item container className={classes.items1}>
          <Grid xs={1} item className={classes.imgWrapper}>
            <img src={iconLG} alt="Logo" width={20} height={20} />
          </Grid>
          <Grid xs={11} item container>
            <Typography className={classes.text2}>
              A Clear picture of ROI
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={6} item container className={classes.items1}>
          <Grid xs={1} item container className={classes.imgWrapper}>
            <img src={iconLG} alt="Logo" width={20} height={20} />
          </Grid>
          <Grid xs={10} item>
            <Typography className={classes.text2}>Subscribers</Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={6} item container className={classes.items1}>
          <Grid xs={1} item container className={classes.imgWrapper}>
            <img src={iconLG} alt="Logo" width={20} height={20} />
          </Grid>
          <Grid xs={10} item container>
            <Typography className={classes.text2}>New Customers</Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={6} item container className={classes.items1}>
          <Grid xs={1} item container className={classes.imgWrapper}>
            <img src={iconLG} alt="Logo" width={20} height={20} />
          </Grid>
          <Grid xs={10} item container>
            <Typography className={classes.text2}>More App installs</Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={6} item container className={classes.items1}>
          <Grid xs={1} item container className={classes.imgWrapper}>
            <img src={iconLG} alt="Logo" width={20} height={20} />
          </Grid>
          <Grid xs={10} item container>
            <Typography className={classes.text2}>Better Reach</Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={6} item container className={classes.items1}>
          <Grid xs={1} item container className={classes.imgWrapper}>
            <img src={iconLG} alt="Logo" width={20} height={20} />
          </Grid>
          <Grid xs={10} item container>
            <Typography className={classes.text2}>New Users</Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={6} item container className={classes.items1}>
          <Grid xs={1} item container className={classes.imgWrapper}>
            <img src={iconLG} alt="Logo" width={20} height={20} />
          </Grid>
          <Grid xs={10} item container>
            <Typography className={classes.text2}>New Leads</Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={6} item container className={classes.items1}>
          <Grid xs={1} item container className={classes.imgWrapper}>
            <img src={iconLG} alt="Logo" width={20} height={20} />
          </Grid>
          <Grid xs={10} item container>
            <Typography className={classes.text2}>
              Customer Retention
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={6} item container className={classes.items1}>
          <Grid xs={1} item container className={classes.imgWrapper}>
            <img src={iconLG} alt="Logo" width={20} height={20} />
          </Grid>
          <Grid xs={10} item container>
            <Typography className={classes.text2}>Sales</Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={6} item container className={classes.items1}>
          <Grid xs={1} item container className={classes.imgWrapper}>
            <img src={iconLG} alt="Logo" width={20} height={20} />
          </Grid>
          <Grid xs={10} item container>
            <Typography className={classes.text2}>Business Growth</Typography>
          </Grid>
        </Grid>
        <Grid
          xs={12}
          md={10}
          lg={8}
          item
          container
          justify="flex-start"
          style={{ paddingTop: "4%" }}
        >
          <CustomButton2
            focusOnContact={props.focusOnContact}
            leftMargin="-14%"
          />
        </Grid>
      </Grid>
      <Grid xs={12} md={8} item container className={classes.itemsContainer1}>
        <Grid xs={12} md={6} item container className={classes.items1}>
          <Grid xs={1} item container className={classes.imgWrapper}>
            <img src={iconLG} alt="Logo" width={20} height={20} />
          </Grid>
          <Grid xs={10} item>
            <Typography className={classes.text2}>
              A Clear picture of ROI
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={6} item container className={classes.items1}>
          <Grid xs={1} item container className={classes.imgWrapper}>
            <img src={iconLG} alt="Logo" width={20} height={20} />
          </Grid>
          <Grid xs={10} item container>
            <Typography className={classes.text2}>New Customers</Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={6} item container className={classes.items1}>
          <Grid xs={1} item container className={classes.imgWrapper}>
            <img src={iconLG} alt="Logo" width={20} height={20} />
          </Grid>
          <Grid xs={10} item container>
            <Typography className={classes.text2}>Better Reach</Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={6} item container className={classes.items1}>
          <Grid xs={1} item container className={classes.imgWrapper}>
            <img src={iconLG} alt="Logo" width={20} height={20} />
          </Grid>
          <Grid xs={10} item container>
            <Typography className={classes.text2}>New Leads</Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={6} item container className={classes.items1}>
          <Grid xs={1} item container className={classes.imgWrapper}>
            <img src={iconLG} alt="Logo" width={20} height={20} />
          </Grid>
          <Grid xs={10} item>
            <Typography className={classes.text2}>Sales</Typography>
          </Grid>
        </Grid>

        <Grid xs={12} md={6} item container className={classes.items1}>
          <Grid xs={1} item container className={classes.imgWrapper}>
            <img src={iconLG} alt="Logo" width={20} height={20} />
          </Grid>
          <Grid xs={10} item container>
            <Typography className={classes.text2}>Subscribers</Typography>
          </Grid>
        </Grid>

        <Grid xs={12} md={6} item container className={classes.items1}>
          <Grid xs={1} item container className={classes.imgWrapper}>
            <img src={iconLG} alt="Logo" width={20} height={20} />
          </Grid>
          <Grid xs={10} item container>
            <Typography className={classes.text2}>More App installs</Typography>
          </Grid>
        </Grid>

        <Grid xs={12} md={6} item container className={classes.items1}>
          <Grid xs={1} item container className={classes.imgWrapper}>
            <img src={iconLG} alt="Logo" width={20} height={20} />
          </Grid>
          <Grid xs={10} item container>
            <Typography className={classes.text2}>New Users</Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={6} item container className={classes.items1}>
          <Grid xs={1} item container className={classes.imgWrapper}>
            <img src={iconLG} alt="Logo" width={20} height={20} />
          </Grid>
          <Grid xs={10} item container>
            <Typography className={classes.text2}>
              Customer Retention
            </Typography>
          </Grid>
        </Grid>
        <Grid xs={12} md={6} item container className={classes.items1}>
          <Grid xs={1} item container className={classes.imgWrapper}>
            <img src={iconLG} alt="Logo" width={20} height={20} />
          </Grid>
          <Grid xs={10} item container>
            <Typography className={classes.text2}>Business Growth</Typography>
          </Grid>
        </Grid>
      </Grid>

      <CustomButton3 focusOnContact={props.focusOnContact} />
    </Grid>
  );
});

export default Expect;
