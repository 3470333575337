import React from "react";

const TermsAndConditions = (props) => {
  return (
    <div style={{ textAlign: "left", padding: "10%" }}>
      <h1>Terms And Conditions For Other Merchants On Adyll</h1>
      <h4>
        Before you can proceed with registration, please read and accept the
        terms and conditions below, please read and print for future reference.
      </h4>
      <p>
        If you wish to participate in our Affiliate program, indicate your
        agreement to do so by clicking the “sign up now” button below.
        Additionally, by accessing and utilizing any of Adyll Marketing Tools or
        accepting of any reward, bonus or payout whether contained in this
        agreement or elsewhere on our web site, you are deemed to have agreed to
        be bound by all the terms and conditions set out in this agreement. For
        purposes of clarity, the terms “we” and “us” refer to Adyll and “you”
        and “Member” refers to the other party to the contract formed by the
        acceptance of these Terms and Conditions. The term “Merchant” is defined
        as any company that has contracted Adyll to promote their Site(s) and/or
        products. The contract provides you with the non-exclusive right to
        direct users (“Visitors”) from your site or sites to the Merchant’s
        websites, in return for the payment of payouts and referral bonuses as
        specified below.
      </p>
      <h2>ENROLLMENT</h2>
      <p>
        To enroll please read this Agreement and then submit a complete Adyll
        Member Account application to us via our Web site. We will evaluate your
        application and notify you whether your application was accepted. Your
        application will be rejected if we determine, in our sole discretion,
        that your site is unsuitable for any reason, including but not limited
        to, sites that are under construction, aimed at children, promote
        sexually explicit materials, promote violence, promote discrimination
        based on race, sex, religion, nationality, disability, sexual
        orientation, or age, promote illegal activities, or violate intellectual
        property rights.
      </p>
      <h2>MEMBER AGREEMENT</h2>
      <p>
        During the term of this arrangement (which shall commence when you
        indicate your acceptance in the manner specified above, and shall end
        when either you or we notify the other, by email, of the termination of
        this Agreement), you shall display a banner or banners provided by Adyll
        on your site (the “Member site”) as a hyperlink to direct Visitors from
        the Member Site to the Merchant’s Sites, using distinct URLs supplied by
        Adyll exclusively for linking (the “Supplied Banners”).
      </p>
      <p>
        The Merchant’s banners and/or text links shall be displayed on the
        Member site. Members can also provide descriptive information regard the
        Merchant Sites(s) on its websites(s). If the content on the member site
        is found to be inaccurate or outdated, then we may notify you of any
        changes to your Site(s) that we feel should be made. The relationship
        specified in this Agreement is non-exclusive for both parties;
        therefore, the Member shall be entitled to display the banners of, and
        provide links to, sites of other companies through the Member site, and
        Adyll shall be entitled to make the Merchant’s banners available through
        online and other services than the Member site
      </p>
      <h2>DELIVERY AND DISPLAY OF BANNERS, COPY, AND PROMOTIONAL MATERIAL</h2>
      <p>
        As a Member, you will have access through Adyll site to a variety of
        graphic and textual links (each of these links sometimes being referred
        to herein as “Links” or, individual, as a “Link”.) Adyll and the
        Merchant hereby grants to the Member the non-exclusive,
        non-transferable, non-assignable (except as provided below) rights,
        during the term of this Agreement, to use (which shall include the right
        to copy, transmit, distribute, display and perform both privately and
        publicly): Adyll’ and the Merchant’s banners, name, site name, and other
        related textual and graphic material are made available by Adyll and/or
        the Merchant to the Member for the express purpose of inclusion on the
        Member’s site from time to time (collectively, the “Merchant’s
        Material”) and for the specific purposes authorized above. Adyll and the
        Merchant authorize the Member to advertise and promote their respective
        promotional material. The copywriting of promotions may not be modified
        nor misrepresented by the Member. Adyll and the Merchant also authorizes
        the Member to refer, in the Member’s advertising and promotions, to the
        fact that the Adyll and Merchant’s sites are accessible through the
        Member site, provided that any such statement:
      </p>
      <p>
        (a)does not include any trademarks, service marks, design marks, symbols
        and/or other indicia of origin of Adyll or the Merchant other than Adyll
        or the Merchant’s Site(s) name and/or site names in a non-distinctive
        typeface (specifically, not the typeface used in the logo design of any
        of Adyll’ or the Merchant’s mark);
      </p>
      <p>
        (b)does not state, suggest, or imply, by the wording or prominence of
        such statement or otherwise, that the Merchant sponsors, authorizes,
        and/or is the source or origin of the Member site; and
      </p>
      <p>
        (c)does not disparage Adyll or the Merchant, its products, services, or
        members. All use of the Adyll or Merchant’s Materials hereunder shall
        inure to the benefit of the Merchant and shall not create any rights,
        title or interest in them for the Member. No other use of the Adyll or
        Merchant’s names, trademarks, service marks, design marks, symbols
        and/or other indicia of origin or other designations confusingly similar
        to any of the foregoing may be made by the Member for any purpose
        without the prior written approval of Adyll or the Merchant. As between
        the Merchant and the Member, the Merchant owns, and shall continue to
        own exclusively, all right title and interest (including without
        limitation, all rights provided under the law of copyright and
        trademark) in and to the Merchant’s Materials and all names, trademarks,
        service marks, design marks, symbols and/or other indicia of origin
        therein throughout the world and in perpetuity, subject to the
        permissions granted in this Agreement.
      </p>
      <h2>MAINTAINING THE LINKS</h2>
      <p>
        You agree to fully cooperate with us in order to establish and maintain
        the Links between Your Site and the Adyll Site or the Merchant’s
        Site(s). The maintenance and updating of Your Site will be your
        responsibility. The graphics and/or text associated with the Links to
        the Adyll Site or the Merchant’s Sites will be updated periodically and
        it will be necessary for you to update the content of Your Site
        accordingly on a regular basis. Please be aware that we have the right
        to monitor Your Site at any time to determine if you are following the
        terms and conditions of this Agreement and to notify you of any changes
        to Your Site that we feel should be made. You are not permitted to mask
        the destination URL information or use any other technique that may mask
        or hide the Adyll or the Merchant site URLs
      </p>
      <h2>SMS MARKETING</h2>
      <p>
        You are not permitted to promote your own copy for SMS marketing that
        has not been signed off by Adyll. You are responsible for the contents
        of your text messages and the consequences thereof. You agree not to use
        Mobile SMS Marketing to send any text messages without prior Adyll
        written permission. You agree not to use Mobile SMS Marketing to send
        any text messages that are not based in customer opt-in or material that
        are unlawful, harassing, libelous, abusive, threatening, harmful,
        vulgar, obscene or may otherwise constitute a criminal offense, give
        rise to civil liability or otherwise objectionable material of any kind
        or nature or that encourages conduct that could constitute a criminal
        offense, give rise to civil liability or otherwise violate any
        applicable local, state, national or international law, regulation or
        court order. Adyll reserves the right to terminate your account without
        prior notice if Adyll becomes aware of and determines, in its sole
        discretion, that you are violating any of the foregoing guidelines
      </p>
      <h2>COMMUNICATION TO MEMBERS</h2>
      <p>
        By signing up to Adyll you agree to receive a variety of material from
        Adyll and its partners. If you choose to opt out of our communications
        Adyll and its Merchants will not be responsible for any damages that may
        occur from members opting out of communications
      </p>
      <h2>GOOD FAITH</h2>
      <p>
        You will not knowingly benefit from known or suspected traffic not
        generated using accepted Internet marketing practices whether or not it
        causes Adyll or the Merchant harm. Should fraudulent activity, knowingly
        or otherwise, arise from a person directed to Merchant’s site via your
        link, we retain the right to retract the commissions paid to you at any
        time. Our decision in this regard will be final and no correspondence
        will be entered into. We reserve the right to retain all amounts due to
        you under this Agreement if we have reasonable cause to believe that
        such traffic has been caused with your knowledge
      </p>
      <h2>CONFIDENTIAL INFORMATION</h2>
      <p>
        As used herein, “Confidential Information” shall mean all oral or
        written information, of whatever kind and in whatever form, relating to
        past, present or future products, software, research, development,
        inventions, processes, techniques, designs or other technical
        information and data, and marketing plans (including such information of
        third parties that a party hereto is obligated to hold as confidential),
        provided that such information has been reasonably identified as or
        could be reasonably considered to be proprietary or confidential, that
        either party:
      </p>
      <p>
        (a) may have received prior to the date of this Agreement, whether
        directly from the other or indirectly from third parties; or
      </p>
      <p>
        (b) may receive hereunder from the other. Each party agrees that, with
        respect to its receipt of Confidential Information of the other party,
        it shall:
      </p>
      <p>
        (i) use the same care and discretion to prevent disclosure of such
        Confidential Information as it uses with similar Confidential
        Information of its own that it does not desire to disclose, but in no
        event with less than a reasonable degree of care;
      </p>
      <p>
        (ii) accept such Confidential Information and use such Confidential
        Information only for the purposes permitted hereunder; and
      </p>
      <p>
        (iii) restrict disclosure of Confidential Information solely to those of
        its employees and agents who have a need to know and are obligated not
        to disclose such Confidential Information to any third parties.
      </p>
      <h4>The foregoing restrictions shall not apply to information that:</h4>
      <p>
        (a) is or hereafter becomes part of the public domain through no
        wrongful act, fault, or negligence on the part of the recipient;
      </p>
      <p>
        (b) the recipient can reasonably demonstrate is already in its
        possession and not subject to an existing agreement of confidentiality;
      </p>
      <p>
        (c) is received from a third party without restriction and without
        breach of this Agreement;
      </p>
      <h2>LIABILITY</h2>
      <p>
        The Member acknowledges that Adyll does not advocate or endorse the
        purchase or the use of any services offered by the Merchant through the
        Merchant’s sites or through its software, nor does it guarantee the
        quality, fitness, or results of any such service or compliance with any
        law or regulation. The Merchant represents and warrants that:
      </p>
      <p>
        (a) The Merchant has the right to enter into this Agreement and to grant
        the rights and licenses granted herein; and
      </p>
      <p>
        (b) The Merchant’s software, and the reproduction, distribution,
        transmission, public performance and public display of the Merchant’s
        Material in connection with the Member site, do not:
      </p>
      <p>
        (i) invade the right of privacy or publicity of any third person; or
      </p>
      <p>
        (ii) contain any libellous, obscene, indecent or otherwise unlawful
        material.
      </p>
      <h2>The Member represents and warrants that:</h2>

      <p>the Member has the right to enter into this Agreement;</p>
      <p>
        the Member site does not, and the reproduction, distribution,
        transmission, public performance and public display of the Member
        Materials as permitted herein, do not:
      </p>
      <p>(i) invade the right of privacy or publicity of any third person,</p>
      <p>
        (ii) contain any libellous, obscene, indecent or otherwise unlawful
        material, or
      </p>
      <p>
        (iii) infringe any patent, copyright or trademark right in any
        jurisdiction; and
      </p>
      <p>
        the Member has received no notice of such invasion, violation or
        infringement of rights
      </p>
      <h2>TERM AND TERMINATION</h2>
      <p>
        The term of this Agreement will begin when you download a banner and
        link it to our site or the Merchant’s Site(s) and will be continuous
        unless and until either party notifies the other in writing that it
        wishes to terminate the Agreement, in which case this Agreement may be
        terminated immediately. TERMINATION IS AT WILL, FOR ANY REASON, BY
        EITHER PARTY. For purposes of notification of termination, delivery via
        e-mail is considered a written and immediate form of notification. Upon
        termination:
      </p>
      <p>
        * You must remove the Adyll or Merchant’s banner/s from your site and
        disable any links from your site to ours and theirs.
      </p>
      <p>
        * All rights and licenses given to you in this Agreement shall
        immediately terminate.
      </p>
      <p>
        * You will be entitled only to those unpaid referral fees, if any,
        earned by you on or prior to the date of termination. You will not be
        entitled to referral fees occurring after the date of termination.
      </p>
      <p>
        * If you have failed to fulfil your obligations and responsibilities, we
        will not pay you the referral fees otherwise owing to you on
        termination.
      </p>
      <p>
        * We may withhold your final payment for a reasonable time to ensure
        that the correct amount is paid.
      </p>
      <p>
        * If we continue to permit activity (generation of revenue) from
        customers after termination, this will not constitute a continuation or
        renewal of this Agreement or a waiver of termination.
      </p>
      <p>
        * You will return to us any confidential information, and all copies of
        it in your possession, custody and control and will cease all uses of
        any trade names, trademarks, service marks, logos and other designations
        of our Merchants.
      </p>
      <p>
        * You and we will be released from all obligations and liabilities to
        each other occurring or arising after the date of such termination,
        except with respect to those obligations which by their nature are
        designed to survive termination, as set out in this Agreement.
        Termination will not relieve you from any liability arising from any
        breach of this Agreement which occurred prior to termination
      </p>
      <h2>
        WE MAY TERMINATE THIS AGREEMENT IF WE DETERMINE (IN OUR SOLE DISCRETION)
        THAT YOUR SITE IS UNSUITABLE
      </h2>
      <p>
        Adyll may choose to cancel an affiliate’s participation in the program,
        at its absolute discretion, if the affiliate is in breach of this
        contract or if it reasonably believes the affiliate to have behaved in
        such a manner contrary to the terms or intent of the program. If the
        affiliate ceases to be a member of the affiliate network, Adyll reserves
        the right to terminate this agreement.
      </p>
      <p>
        Unsuitable sites may include those that: are aimed at children, promote
        sexually explicit materials, promote violence, promote discrimination
        based on race, sex, religion, nationality, disability, sexual
        orientation, or age, promote illegal activities, or violate intellectual
        property rights.
      </p>
      <p>
        For the avoidance of doubt, termination of the agreement will cease any
        commission payment that may be earned by the Member from net gaming
        revenue generated post the termination of the agreement.
      </p>
      <h2>RISK ALLOCATION</h2>
      <p>
        Neither party will be liable to the other party (nor to any person
        claiming rights derived from the other party’s rights) for incidental,
        indirect, consequential, special, punitive or exemplary damages of any
        kind — including lost revenue or profits, loss of business, or loss of
        data — arising out of this Agreement (including without limitation as a
        result of any breach of any warranty or other term of this Agreement),
        regardless of whether the party liable or allegedly liable was advised,
        had other reason to know, or in fact knew of the possibility thereof.
      </p>
      <h2>ACKNOWLEDGEMENT OF NO WARRANTY</h2>
      <p>
        Except as expressly provided herein, neither party warrants that their
        respective websites will perform in the manner expected or without
        interruption, error, or defect, or that any revenue to either party will
        result from the activities contemplated by this Agreement. Except as
        expressly set forth in this agreement, neither party makes any
        warranties of any kind, express or implied, including warranties of
        merchantability or fitness for a particular purpose or warranties
        against infringement of any intellectual property rights not
        specifically enumerated.
      </p>
      <h2>ASSIGNMENT</h2>
      <p>
        Except as otherwise provided herein, neither Adyll nor the Member may
        assign this Agreement or any of its rights or delegate any of its duties
        under this Agreement, without the prior written consent of the other.
        Any purported assignment or delegation without such required consent
        shall be null and void.
      </p>
      <h2>GOVERNING LAW</h2>
      <p>
        This Agreement, its interpretation, performance or any breach thereof,
        shall be construed in accordance with, and all questions with respect
        thereto shall be determined by, the internal substantive laws of United
        Kingdom.
      </p>
      <h2>SINGLE ACCOUNT</h2>
      <p>
        The Member agrees to operate a single Member site with the Merchant’s
        Site(s). If Adyll should discover, using information it deems
        appropriate in the circumstances, that the Member is operating more than
        one Member account, this Agreement shall terminate and the Member will
        forfeit all rights to commissions and referral bonuses. Several sites
        may be registered to the one account; however, the placement of any site
        banner on a new site, under the Adyll Members Program, MUST be reported
        by the Member and cleared by the Adyll Support Team beforehand, and
        failure to do so may result in the termination of the Member’s account.
        The Member will also forfeit all rights to commissions and referral
        bonuses.
      </p>
      <h2>INDEPENDENT CONTRACTORS</h2>
      <p>
        Adyll and the Member are independent contractors under this Agreement,
        and nothing herein shall be construed to create a partnership, joint
        venture, or agency relationship between Adyll and the Member. Neither
        party has the authority to enter into Agreements of any kind on behalf
        of the other party.
      </p>
      <h2>DISCLAIMER</h2>
      <p>
        It is the Member’s responsibility to declare taxes from their Member’s
        profits, according to their country’s regulations. It is the merchant’s
        responsibility to pay its members and Adyll will not be held responsible
        for the actions of its merchants in regards to the payment of its
        members.
      </p>
      <p>
        Adyll is also not responsible for the way cookies are tracked on the
        Merchants site or on the member’s site. The maintenance of the
        cookie/tracking code is the responsibility of the Merchant. Adyll is
        strictly a housing software that displays the results of the tracking
        for its members
      </p>
      <h2>WAIVER</h2>
      <p>
        No waiver of any breach of any provision of this Agreement shall
        constitute a waiver of any prior, concurrent or subsequent breach of the
        same or any other provisions hereof, and no waiver shall be effective
        unless made in writing and signed by an authorized representative of the
        waiving party.
      </p>
      <h2>MODIFICATIONS</h2>
      <p>
        Adyll may modify any of the Terms and Conditions set forth in this
        Agreement by posting the modified Terms of Use https://www.Adyll.co.uk/
        or at another site it chooses, and by notifying the Member of the
        modifications via email notice. Modifications may include, for example,
        changes in the scope of available referral fees, fee schedules, payment
        procedures, commission structure, payment threshold and referral program
        rules. IF ANY MODIFICATION IS UNACCEPTABLE TO YOU, YOUR ONLY RECOURSE IS
        TO TERMINATE THIS AGREEMENT. YOUR CONTINUED PARTICIPATION IN THE PROGRAM
        FOLLOWING OUR POSTING OF A CHANGE NOTICE OR NEW AGREEMENT ON OUR SITE
        WILL CONSTITUTE BINDING ACCEPTANCE OF THE CHANGE.
      </p>
    </div>
  );
};

export default TermsAndConditions;
